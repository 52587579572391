$platform-name: 'British Thyroid';
$assets-path: "../assets/" ;

/* Colours */
$brand-primary: #FFBD59;
$brand-secondary: #2DAAE2;
$brand-tertiary: #12A19B;
$brand-quarternary: #086634;
$brand-quinary: #2FAD66;

$bg-palette-dark: $brand-tertiary;
$bg-palette-light: #E1F8F7;
$bg-palette-color: #FFFFFF;

$link-colour: $brand-quarternary;
$font-palette-black: #000000;
$font-palette-green: $brand-quarternary;

$black: #000000;
$white: #FFFFFF;
$bg-color: #FFFFFF;
$bg-grey: #F4F3F8;
$bg-dark: #000000;

/* Fonts */
@font-face {
  font-family: "Open Sans";
  src: url($assets-path + "OpenSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url($assets-path + "OpenSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Archivo Black";
  src: url($assets-path + "ArchivoBlack-Regular.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

// Typography
$font-family-base: "Open Sans", sans-serif;
$headings-font-family: "Archivo Black", sans-serif;
$font-size-base: 16px;
$font-size-large: 1.25rem;
$font-size-small: 0.875rem;

$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;
$headings-font-weight: 800;

$font-size-h1: 39.81px;
$font-size-h2: 33.18px;
$font-size-h3: 27.65px;
$font-size-h4: 23.04px;
$font-size-h5: 19.20px;
$font-size-h6: 16px;

$font-mobile-h1: 29px;
$font-mobile-h2: 26px;
$font-mobile-h3: 23px;
$font-mobile-h4: 20px;
$font-mobile-h5: 18px;

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 1.5s;
$transition-curve: ease;

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 1.5s;
$transition-curve: ease;

// Layout
$container-max-width: 1600px;
$container-grid-size: 85%;
$site-bleed: 0px;

// Logo
$logo: "logo.svg";
$logo-width: 115px;
$logo-height: 115px;
$logo-mobile-width: 72px;
$logo-mobile-height: 72px;

// Menu admin
$menu-admin-enabled: false;

// Navigation
$nav-normal-margin-top: -50px;
$nav-normal-align-items: right; // left, center, right
$nav-breakpoint: 1024px;

// Search - input
$header-search-input-max-width: 250px;
$header-search-input-border-radius: 5px;
$header-search-input-border-colour: rgba(18, 161, 155, 1);
$header-search-input-border-width: 1px 1px 1px 1px ; // t-r-b-l
$header-search-input-background-colour: rgba(255, 255, 255, 1);

// Search - button
$header-search-button-width: 12px;
$header-search-button-height: 15px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: 12px;
$header-search-button-icon-colour: $font-palette-black;
$header-search-button-border-radius: 0;

// Buttons
$btn-background-colour: $brand-primary;
$btn-text-colour: $bg-dark;
$btn-padding-x: 31.5px;
$btn-padding-y: 17px;
$btn-font-weight: $font-weight-bold;
$btn-font-size: $font-size-base;
$btn-line-height: 1.375;
$btn-border-radius: 5px;

// Main call to action
$cta-btn-background-colour: $brand-secondary;

// Slick
$slick-enabled: true;

// Header content
$header-content-max-width: $container-max-width; // 100%
$header-content-margin-top: 0;
$header-content-padding-top: 2px;

// Navigation
$nav-background-colour: $white;
$nav-normal-mobile-background-colour: $bg-palette-light;

// Top level items
$nav-top-level-item-padding: 20px 0;
$nav-top-level-item-colour: $font-palette-black;
$nav-top-level-item-font-weight: bold;
$nav-top-level-item-background-colour: $white;
$nav-top-level-item-hover-background-colour: $white;

// Home intro
$home-intro-max-width: 100%;
$home-intro-background-colour: transparent;
$home-intro-background-image: "why-shapes.svg";
$home-intro-background-image-opacity: 1;
$home-intro-margin-top: 0;
$home-intro-text-align: center;

// Home features
$home-features-padding-top: 0;
$home-features-padding-bottom: 0;

// Home feeds
$home-feeds-padding-top: 0;
$home-feeds-padding-bottom: 0;

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-breakpoint: 1229px;
$carousel-details-style: adjacent; // overlay, adjacent or below
$carousel-details-background-colour: transparent;
$carousel-details-max-width: 100%;
$carousel-details-padding: 0;
$carousel-details-border-radius: 0;
$carousel-heading-colour: $bg-dark;
$carousel-heading-font-size: $font-size-h1;
$carousel-summary-colour: $bg-dark;
$carousel-summary-font-size: $font-size-large;
$carousel-read-more-background-colour: $brand-primary;

// Carousel controls (left-right buttons)
$carousel-controls-button-size: 50px;
$carousel-controls-button-gap: 20px;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-button-border-radius: 0;
$carousel-controls-button-box-shadow: transparent;
$carousel-controls-position-y: bottom; // top, center, bottom
$carousel-controls-position-x: left; // split, left or right
$carousel-controls-icon-colour: transparent;
$carousel-controls-next-icon: "\f054"; // TODO: These are temporarily suspended
$carousel-controls-prev-icon: "\f053"; // TODO: These are temporarily suspended

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: transparent;
$carousel-mobile-heading-colour: $bg-dark;
$carousel-mobile-summary-colour: $bg-dark;

// Submenus
$nav-submenu-background-colour: $bg-palette-light;
$nav-submenu-item-colour: $bg-dark;
$nav-submenu-item-font-weight: $font-weight-bold;
$nav-submenu-item-hover-background-colour: $brand-tertiary;

// Impact stats
$impact-stats-max-width: 100%;
$impact-stats-padding-y: 0;
$impact-stats-background-colour: transparent;
$impact-stats-border-radius: 0;
$impact-stats-text-align: left; // left, center, right

// Impact stats - Figure
$impact-stats-figure-colour: black;
$impact-stats-figure-font-size: $font-size-h2;

// Impact stats - Individual stat
$impact-stats-summary-font-size: $font-size-base;
$impact-stat-margin: 0;

// Card
$card-border-radius: 0 0 10px 10px;
$card-border: 0px;
$card-image-background-colour: $brand-secondary;
$card-details-background-colour: $brand-tertiary;
$card-heading-colour: $font-palette-black;
$card-summary-colour: $font-palette-black;
$card-details-padding: 30px;
$card-heading-font-size: $font-size-h5;

// Newsletter
$newsletter-heading-text-align: left; // left, center, right
$newsletter-background-colour: transparent;

// Newsletter hero - full width form above rest of footer
$newsletter-hero-padding-y: 0;

// Footer social
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: black;
$social-icons-font-size: 24px;
$social-icons-gap: 5px;
$social-icons-hover-colour: $brand-primary; // A colour, or 'brand'

// Footer
$footer-boxes-count: 5;
$footer-prefab: 1;
$footer-background-colour: $brand-tertiary;
$footer-colour: black;
$footer-padding-y: 0;

// Event feed items
$feed-item-event-location-enabled: false;

// Event cards
$event-card-floating-date: true;
$event-card-floating-date-background-colour: $brand-secondary;
$event-card-floating-date-border-radius: 20px;

// Card hover state
$card-hover-image-opacity: 1;
$card-hover-details-background-colour: $bg-palette-light;

// Blockquote
$blockquote-background-colour: $brand-tertiary;
$blockquote-colour: black;
$blockquote-padding: 60px 30px 40px;
$blockquote-text-align: left; // left, center, right
$blockquote-line-height: 1.375;
$blockquote-font-family: $headings-font-family;
$blockquote-font-size: $font-size-base; // Em unit will cascade to children

// Sidebar
$sidebar-layout: below; // adjacent, below or above - where do you want the sidebar to be positioned on posts?
$sidebar-adjacent-gap: 50px; // distance between sidebar and post content
$sidebar-border-radius: 0;

// Associated lists
$associated-latest-enabled: false;
$associated-related-enabled: true;
$associated-most-read-enabled: false;
$associated-max-number-of-posts: 3;
$associated-list-heading-font-size: $font-size-h2;
$associated-item-image-enabled: true;
$associated-item-heading-font-size: $font-size-h5;
$associated-item-heading-colour: black;
$associated-item-summary-enabled: true;
$associated-item-border: false;

// Product-price
$product-price-colour: $black;

// Share this page
$share-label-font-size: $font-size-h4;
$share-label-font-family: $headings-font-family;

// Footer admin links
$footer-admin-basket-link-enabled: false;

// Shop
$basket-link-only-on-shop-pages: true; // Set to true to only show the basket link (in both header and footer admin menus) on 'shop' related pages only